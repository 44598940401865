import SideBar from "../components/sidebar";
import { Outlet, Link, BrowserRouter, Route, Routes } from "react-router-dom";
import SuppliersListPage from "./suppliers/suppliers_list_page";
import AddProductPage from "./add_product_page";

import axios from "axios";
import { createContext } from "react";
import { Axios } from "axios";
import { rootUrl } from "../../constants";
import SupllierDetailPage from "./suppliers/suppliers_detail_page";
import ClientsListPage from "./clients/clients_list_page";
import ClientDetailPage from "./clients/client_detail_page";
import WarehouseListPage from "./warehouses/warehouse_list_page";
import ProductsListPage from "./product/product_list_page";
import ProductDetailPage from "./product/product_detail_page";
import WarehouseDetailPage from "./warehouses/warehouse_detail_page";
import AddPurchasePage from "./purchases/add_purchase_page";
import AddSalePage from "./sales/create_sale_page";
import PurchasesListPage from "./purchases/purchases_list";
import SalesListPage from "./sales/sale_list_page";
import TresoryPage from "./tresorie/tresory_page";
import { DashboardPage } from "./dashboard/dashboard_page";
import { PortfolioPage } from "../../potfolio";

// create a context

const ApiContext = createContext<Partial<{ instance: Axios }>>({});

function App() {
  const axiosInstance = axios.create({
    baseURL: rootUrl,
  });

  return (
    <div className=" w-full bg-[#F5F1F1]">
      <PortfolioPage />
    </div>
  );

  //   <ApiContext.Provider
  //     value={{
  //       instance: axiosInstance,
  //     }}
  //   >
  //     <BrowserRouter>
  //       <Routes>
  //         <Route path="/" element={<Layout />}>
  //           <Route index element={<DashboardPage />} />
  //           <Route path="fournisseurs" element={<SuppliersListPage />} />
  //           <Route path="fournisseurs/:id" element={<SupllierDetailPage />} />
  //           <Route path="clients" element={<ClientsListPage />} />
  //           <Route path="clients/:id" element={<ClientDetailPage />} />
  //           <Route path="entrepots" element={<WarehouseListPage />} />
  //           <Route path="entrepots/:id" element={<WarehouseDetailPage />} />
  //           <Route path="produits" element={<ProductsListPage />} />
  //           <Route path="produits/:id" element={<ProductDetailPage />} />
  //           <Route path="produits/ajouter" element={<AddProductPage />} />
  //           <Route path="achats" element={<PurchasesListPage />} />
  //           <Route path="achats/ajouter" element={<AddPurchasePage />} />
  //           <Route path="ventes" element={<SalesListPage />} />
  //           <Route path="ventes/ajouter" element={<AddSalePage />} />
  //           <Route path="tresorie" element={<TresoryPage />} />
  //         </Route>
  //       </Routes>
  //     </BrowserRouter>
  //   </ApiContext.Provider>
  // );
}

function Layout() {
  return (
    <div className="h-screen bg-gray-50">
      <div className="fixed left-0 top-0 z-10 h-screen w-[240px]">
        <SideBar />
      </div>
      <div className="ml-[240px] h-full">
        <div>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default App;
