import smailImgBW from "./assets/ismail_img_bw.jpg";
import smailImg from "./assets/ismail_img.jpg";
import aboutImg from "./assets/about_img.png";
import hairstylImg from "./assets/projects/hairstyl.png";
import mamaisonImg from "./assets/projects/ma maison.png";
import puzzlesImg from "./assets/projects/puzzle.png";
import kmosImg from "./assets/projects/kosmo tech.png";
import mauriCom from "./assets/projects/mauricom.png";

import cv from "./assets/cv.pdf";
import maurPdf from "./assets/Mauritanie hopitales.pdf";

import { useRef, useState } from "react";

interface Project {
  title: string;
  group: string;
  category: string;
  year: number;
  img: string;
  link: string;
}

const projects: Project[] = [
  {
    title: "HAIRSTYL",
    group: "BARBER BOOKING",
    category: "Mobile app",
    year: 2023,
    img: hairstylImg,
    link: "https://www.behance.net/gallery/185679111/Hairstyl-app-Ui-design",
  },
  {
    title: "MA MAISON",
    group: "HOUSE RENTALS",
    category: "Mobile app",
    year: 2023,
    img: mamaisonImg,
    link: "https://www.behance.net/gallery/185680511/Ma-Maison-App-Ui-design",
  },
  {
    title: "MAURICOM",
    group: "ECOM APP",
    category: "E-commerce",
    year: 2023,
    img: mauriCom,
    link: "https://www.behance.net/gallery/185681343/Mauricom-App",
  },
  {
    title: "MEDIGESTION",
    group: "HOSPITAL MANAGEMENT",
    category: "Website",
    year: 2023,
    img: kmosImg,
    link: maurPdf,
  },
];

function OutlinedText({ text }: { text: string }) {
  return (
    <h4 className=" font-MonumentExtended text-shadow-outlined group-hover:text-shadow-none text-6xl text-[#F5F1F1] transition-colors group-hover:text-white">
      {text}
    </h4>
  );
}
// ml-3 font-sans text-base font-semibold text-gray-400 group-hover:text-white
// bg-[url('../assets/ismail_img_bw.jpg')]
function PorjectItem({ title, year, group, category, img, link }: Project) {
  return (
    <a href={link} target="_blank">
      <div className="group relative h-44 cursor-pointer ">
        <div className=" absolute inset-y-0 bottom-0 top-0 z-10 flex flex-col justify-center  bg-cover">
          <div className="flex">
            <OutlinedText text={title + " /"} />
            <p className=" ml-3 font-sans text-base font-semibold text-gray-400 group-hover:text-white">
              {year}
            </p>
          </div>
          <div className="flex items-end">
            <OutlinedText text={group} />
            <p className=" ml-3 font-sans text-base font-semibold text-gray-400 group-hover:text-white">
              {category}
            </p>
          </div>
        </div>
        <div
          // alt={title}
          // src={img}
          className="absolute inset-x-0 top-0 -mx-[calc(50vw-600px-10px)] h-44   object-fill opacity-0 transition-opacity duration-300 group-hover:opacity-100"
        >
          <img
            alt={title}
            src={img}
            className=" h-44 w-full object-fill opacity-0 transition-opacity duration-300 group-hover:opacity-100"
          ></img>
        </div>
      </div>
    </a>
  );
}

function Title({ text, id }: { text: string; id: string }) {
  return (
    <div id={id} className=" relative w-max">
      <h4 className="font-MonumentExtended  relative  text-4xl uppercase before:absolute before:inset-x-0 before:bottom-0 before:block before:h-5 before:bg-[#A555EC]">
        <span className="relative">{text}</span>
      </h4>
    </div>
  );
}

interface Skill {
  title: string;
  details: string[][];
}

const skills: Skill[] = [
  {
    title: "Experience",
    details: [
      ["2021 - Now", "Freelance UI/UX Designer", "“Fiverr”"],
      ["2020 - 2021", "Freelance UI/UX Designer", "Freelancer"],
    ],
  },
  {
    title: "Education",
    details: [
      ["20.2023 - Now", "Certificate in UX Design", "“Gomycode”"],
      ["2018", "High school diploma in Mathematics", "“Bouabid”"],
    ],
  },
  {
    title: "Tools",
    details: [["Figma"], ["Adobe Photoshop"], ["Miro"]],
  },
  {
    title: "LANGUAGES",
    details: [["Arabic"], ["French"], ["English"]],
  },
];

export function SkillItem(skill: Skill) {
  const { title } = skill;
  const [isOpened, setIsOpened] = useState(false);

  let ll: string[] = [];
  for (let i = 0; i < skill.details.length; i++) {
    let d = Array.from(skill.details[i]);
    // if d length is < 3 add empty string arround the non empty string
    if (d.length === 1) {
      d = ["", ...d, ""];
    }
    ll = [...ll, ...d];
  }

  return (
    <div
      onClick={() => {
        setIsOpened(!isOpened);
      }}
      className="group flex cursor-pointer flex-col "
    >
      <hr className=" border-1 mb-5 border-[#AAA9A9]" />
      <div className=" flex items-center  justify-between py-16">
        <h3 className=" font-MonumentExtended  text-2xl uppercase">{title}</h3>
        <svg
          className={`h-12 w-12 transition-all ${
            isOpened ? "-rotate-90" : "group-hover:-rotate-45"
          }`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 50 50"
          fill="none"
        >
          <path
            d="M12.5 37.5V33.3333H30.3959L10.4167 13.3542L13.3542 10.4167L33.3334 30.3958V12.5H37.5V37.5H12.5Z"
            fill="black"
          />
        </svg>
      </div>
      {isOpened && (
        <div
          className={`mb-16 grid animate-inAnimation  grid-cols-4 place-items-end justify-end gap-x-5 gap-y-16 self-end`}
        >
          {ll.map((item, index) =>
            item === "" ? (
              <div className="w-20 " />
            ) : (
              <p
                className={` text-base ${
                  index % 3 === 1
                    ? "col-span-2 place-self-start font-bold"
                    : "font-medium "
                }`}
              >
                {item}
              </p>
            ),
          )}
        </div>
      )}
    </div>
  );
}

interface Workflow {
  title: string;
  description: string;
}

const workflows: Workflow[] = [
  {
    title: "Briefing",
    description:
      "Firstly, I ask to provide as much information about the project as possible. Goals, needs, details, and nuances",
  },
  {
    title: "Research",
    description:
      "This step requires research about the industry, as well as competitive and visual analysis",
  },
  {
    title: "Prototyping",
    description:
      "Based on the research and analysis, I develop a structure of the future project and the functionality of each page separately",
  },
  {
    title: "Design",
    description:
      "I curate references, select visuals, and design 2-3 pages. Upon approval, I extend the design to all other pages",
  },
  {
    title: "Adaptation",
    description:
      "I draw adaptive versions for different electronic devices such as tablets and phones",
  },
  {
    title: "Finalization",
    description:
      "I prepare a Figma file with a fully developed project for transferring to a developer",
  },
];

export function WorkflowItem({
  workflow,
  index,
}: {
  workflow: Workflow;
  index: number;
}) {
  return (
    <div className="group relative cursor-pointer">
      <h3 className="font-MonumentExtended text-shadow-outlinedLight group-hover:text-shadow-none absolute left-0  top-0 text-[165px] leading-[130px] text-[#F5F1F1] transition-all group-hover:text-black">
        {index.toString().length === 1 ? "0" + index : index}
      </h3>
      <div className="shadow-card  relative  ml-12 mt-20 flex h-[180px]  flex-col rounded-xl border border-black bg-[#F5F1F1] p-6 transition-all group-hover:bg-black">
        <h3 className=" font-MonumentExtended  text-2xl transition-all group-hover:text-white">
          {workflow.title}
        </h3>
        <p className=" text-sm font-normal transition-all group-hover:text-white">
          {" "}
          {workflow.description}
        </p>
      </div>
    </div>
  );
}
// [1px 1px 1px 0px #000, 1px 1px 1px 0px #000, 2px 2px 1px 0px #000, 2px 2px 1px 0px #000, 3px 3px 1px 0px #000, 3px 3px 1px 0px #000, 4px 4px 1px 0px #000, 4px 4px 1px 0px #000]

export function IsmailImg() {
  const [isHovered, setIsHovered] = useState(false);
  const thisRef = useRef<HTMLImageElement>(null);

  return (
    <img
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      alt="ismail"
      ref={thisRef}
      src={isHovered ? smailImg : smailImgBW}
      className="relative h-[560px] "
    ></img>
  );
}

const contacts = [
  {
    title: "LINKEDIN",
    link: "https://www.linkedin.com/in/ismailbarouri/",
  },
  {
    title: "BEHANCE",
    link: "https://www.behance.net/yookaart",
  },
  {
    title: "DRIBBLE",
    link: null,
  },
  {
    title: "TWITTER",
    link: "https://twitter.com/sma3iiin",
  },
];

export function PortfolioPage() {
  return (
    <html className="scroll-smooth">
      <div className="mx-auto w-[1200px] text-base font-medium">
        <div className="flex justify-between py-10">
          <h3>Ismail BAROURI</h3>
          <div className="flex gap-10">
            <p className="relative before:absolute before:bottom-0 before:left-0 before:h-2 before:w-10 before:bg-[#A555EC] before:opacity-0  before:transition-all before:duration-300 hover:before:opacity-100">
              <a href="#projects" className=" relative">
                Projects
              </a>
            </p>
            <p className="relative before:absolute before:bottom-0 before:left-0 before:h-2 before:w-10 before:bg-[#A555EC] before:opacity-0  before:transition-all before:duration-300 hover:before:opacity-100">
              <a href="#about" className=" relative">
                About
              </a>
            </p>
            <p className="relative before:absolute before:bottom-0 before:left-0 before:h-2 before:w-10 before:bg-[#A555EC] before:opacity-0  before:transition-all before:duration-300 hover:before:opacity-100">
              <a href="#workflow" className=" relative">
                Workflow
              </a>
            </p>
            <p className="relative before:absolute before:bottom-0 before:left-0 before:h-2 before:w-10 before:bg-[#A555EC] before:opacity-0  before:transition-all before:duration-300 hover:before:opacity-100">
              <a href="#contacts" className=" relative">
                Contact
              </a>
            </p>
          </div>
        </div>
        <hr className=" border-1 mb-5 border-[#AAA9A9]"></hr>
        <div className="relative h-[560px]">
          <div className="absolute  z-50  flex h-full flex-col justify-between">
            <h3 className="font-MonumentExtended text-9xl ">
              HELLO!
              <br />
              I'M ISMAIL
            </h3>
            <ul className=" ">
              <li>
                <Title text={"UX/UI DESIGNER"} id={"ui_ux"} />
              </li>
              <li>
                <p className=" mx-auto mt-10 w-[264px] font-medium">
                  I am a skilled UX/UI designer actively seeking opportunities
                  for work, new project engagements, collaborations, and
                  freelance ventures. With a solid background in graphic design
                  spanning two years
                </p>
              </li>
            </ul>
          </div>
          <div className=" absolute right-0 ">
            <IsmailImg />
          </div>
        </div>
        <div className=" mb-20 mt-48 ">
          <Title text={"Projects"} id={"projects"} />
        </div>
        {projects.map((project) => (
          <PorjectItem {...project} />
        ))}
        <div className=" mb-20 mt-48 ">
          <Title text={"About"} id={"about"} />
        </div>
        <div className="relative h-[560px] w-full ">
          <h3 className="font-MonumentExtended absolute top-0 z-10 w-1/2 text-2xl font-extrabold uppercase">
            A lover of minimalistic and aesthetic aesthetics, I'm on a constant
            journey to align myself with the ever-evolving, contemporary design
            trends.
          </h3>
          <div className=" absolute bottom-0  ml-[30%] mr-auto flex h-fit  ">
            <img alt="about" src={aboutImg} className=" h-[500px]"></img>
            <div className="flex w-[400px] flex-col justify-between pl-5">
              <div />
              <p className=" text-sm font-normal uppercase">
                I bring a wealth of experience as a freelance UI/UX designer,
                having successfully navigated projects on both Fiverr and
                Freelancer platforms.
                <br />
                <br />
                On Fiverr, I delivered tailored design solutions, showcasing
                adaptability to diverse client needs and fostering a solid
                portfolio of successful projects.
                <br />
                <br />
                Meanwhile, on Freelancer, I thrived in a dynamic environment,
                rapidly adapting to various design challenges and consistently
                meeting tight deadlines. My experience on both platforms
                underscores my commitment to delivering high-quality design
                services with efficiency and client satisfaction at the
                forefront.
              </p>
              <a
                href={cv}
                target="_blank"
                className=" w-max border border-black px-20 py-4 transition-all duration-300 hover:bg-black hover:text-white"
              >
                DOWNLOAD CV
              </a>
            </div>
          </div>
        </div>
        <div className=" mt-24">
          {skills.map((skill) => (
            <SkillItem {...skill} />
          ))}
        </div>
        <div className=" mb-20 mt-48 ">
          <Title text={"Workflow"} id="workflow" />
        </div>
        <div className=" -ml-20 grid grid-cols-3 gap-y-10">
          {workflows.map((workflow, index) => (
            <WorkflowItem workflow={workflow} index={index + 1} />
          ))}
        </div>
        <div className=" mb-28 mt-48 ">
          <Title text={"CONTACTS"} id="contacts" />
        </div>
        <h3 className=" font-MonumentExtended text-3xl">
          HAKUUNA123@GMAIL.COM
        </h3>
        <div className="mt-[55px] flex ">
          {contacts.map((item, index) => (
            <a href={item.link ?? undefined} className="flex items-center">
              <p
                className={` relative cursor-pointer text-2xl before:absolute before:inset-x-0 before:bottom-0 before:h-[2px] before:bg-black before:opacity-0 before:transition-all before:duration-300 hover:before:opacity-100`}
              >
                {item.title}
              </p>
              {index < 3 && <div className=" mx-16 h-6 w-[2px] bg-black" />}
            </a>
          ))}
        </div>
        <hr className=" border-1  mt-48 border-[#AAA9A9] " />
        <div className="flex justify-between py-2">
          <p className=" text-xs font-normal">© 2023 ISMAIL BAROURI</p>
          <p className=" text-xs font-normal">Let’s make designs together! </p>
        </div>
      </div>
    </html>
  );
}
